import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import VueMask from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueYoutube from 'vue-youtube'
import VueQuill from 'vue-quill'
import vco from "v-click-outside"

import router from './router'
import store from '@/state/store'
import i18n from './i18n'

import "@/assets/scss/app.scss";

import {initFirebaseBackend} from './helpers/firebase/authUtils';

import {configureFakeBackend} from './helpers/fakebackend/fake-backend';
import moment from "moment";
import slugify from "slugify";

const firebaseConfig = {
    apiKey: process.env.VUE_APP_APIKEY,
    authDomain: process.env.VUE_APP_AUTHDOMAIN,
    databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
    projectId: process.env.VUE_APP_PROJECTId,
    storageBucket: process.env.VUE_APP_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
    appId: process.env.VUE_APP_APPID,
    measurementId: process.env.VUE_APP_MEASUREMENTID
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
    initFirebaseBackend(firebaseConfig);
} else {
    configureFakeBackend();
}


Vue.filter('f_date', function (value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
    }
    return '';
})

Vue.filter('f_date_time', function (value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY HH:mm')
    }
    return '';
})

Vue.filter('f_time', function (value) {
    if (value) {
        return moment(String(value)).format('HH:mm')
    }
    return '';
})


Vue.prototype.$slug_format = function(value) {
    let slug = slugify(value)
    slug = slug.toLowerCase()
    return slug
}


Vue.config.productionTip = false
Vue.use(VueYoutube)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(VueQuill)
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
        libraries: 'places',
    },
    installComponents: true
})
Vue.component('apexchart', VueApexCharts)

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
