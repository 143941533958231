
import UsersRoute from '@/modules/users/users-route';
import TemplatesRoute from '@/modules/templates/templates-route';
import SchoolsRoute from '@/modules/schools/schools-route';
import BooksRoute from '@/modules/books/books-route';
import BooksCategoriesRoute from '@/modules/books-categories/books-categories-route';
import BooksPagesRoute from '@/modules/books-pages/books-pages-route';
import BooksValuesRoute from '@/modules/books-values/books-values-route';
import BudgetsAddressRoute from '@/modules/budgets-address/budgets-address-route';
import BudgetsRoute from '@/modules/budgets/budgets-route';
import BudgetsBooksRoute from '@/modules/budgets-books/budgets-books-route';
import BudgetsCommentsRoute from '@/modules/budgets-comments/budgets-comments-route';
import BudgetsDevolutionsRoute from '@/modules/budgets-devolutions/budgets-devolutions-route';
import BudgetsDevolutionsBooksRoute from '@/modules/budgets-devolutions-books/budgets-devolutions-books-route';
import BudgetsInvoicesRoute from '@/modules/budgets-invoices/budgets-invoices-route';
import BudgetsInvoicesSlipsRoute from '@/modules/budgets-invoices-slips/budgets-invoices-slips-route';
import BudgetsStatusRoute from '@/modules/budgets-status/budgets-status-route';
import BudgetsStepsRoute from '@/modules/budgets-steps/budgets-steps-route';
import BudgetsStepsNextRoute from '@/modules/budgets-steps-next/budgets-steps-next-route';
import ContractsRoute from '@/modules/contracts/contracts-route';
import ContractsAttachmentsRoute from '@/modules/contracts-attachments/contracts-attachments-route';
import ContractsCommentsRoute from '@/modules/contracts-comments/contracts-comments-route';
import ContractsFieldsRoute from '@/modules/contracts-fields/contracts-fields-route';
import ContractsSubscriptionsRoute from '@/modules/contracts-subscriptions/contracts-subscriptions-route';
import PeoplesRoute from '@/modules/peoples/peoples-route';
import PeoplesRacesRoute from '@/modules/peoples-races/peoples-races-route';
import PeoplesTypesRoute from '@/modules/peoples-types/peoples-types-route';
import SchoolsContractsRoute from '@/modules/schools-contracts/schools-contracts-route';
import SchoolsContractsFieldsRoute from '@/modules/schools-contracts-fields/schools-contracts-fields-route';
import SchoolsInfosRoute from '@/modules/schools-infos/schools-infos-route';
import SchoolsTypesRoute from '@/modules/schools-types/schools-types-route';
import CoreFormsRoute from "@/modules/core-forms/core-forms-route";
import TaxonomiesTypesRoute from '@/modules/taxonomies-types/taxonomies-types-route';
import TaxonomiesRoute from '@/modules/taxonomies/taxonomies-route';
import PostsTypesRoute from '@/modules/posts-types/posts-types-route';
import PostsConfigsRoute from '@/modules/posts-configs/posts-configs-route';
import PostsRoute from '@/modules/posts/posts-route';


export default [
	UsersRoute,
	TemplatesRoute,
	SchoolsRoute,
	BooksRoute,
	BooksCategoriesRoute,
	BooksPagesRoute,
	BooksValuesRoute,
	BudgetsAddressRoute,
	BudgetsRoute,
	BudgetsBooksRoute,
	BudgetsCommentsRoute,
	BudgetsDevolutionsRoute,
	BudgetsDevolutionsBooksRoute,
	BudgetsInvoicesRoute,
	BudgetsInvoicesSlipsRoute,
	BudgetsStatusRoute,
	BudgetsStepsRoute,
	BudgetsStepsNextRoute,
	CoreFormsRoute,
	ContractsRoute,
	ContractsAttachmentsRoute,
	ContractsCommentsRoute,
	ContractsFieldsRoute,
	ContractsSubscriptionsRoute,
	PeoplesRoute,
	PeoplesRacesRoute,
	PeoplesTypesRoute,
	SchoolsContractsRoute,
	SchoolsContractsFieldsRoute,
	SchoolsInfosRoute,
	SchoolsTypesRoute,
	TaxonomiesTypesRoute,
	TaxonomiesRoute,
	PostsTypesRoute,
	PostsConfigsRoute,
	PostsRoute,
] 
